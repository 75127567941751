<template>
    <div class="coverContainer" :class="{ twoMax: twoMax }">
      <AnimeCover v-for="ani in anime" :anime="ani" :key="ani.id" />
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { IAnime } from "@/models/anime";
import AnimeCover from '@/components/AnimeCover.vue';

@Options({
  components: {
    AnimeCover,
  },
  props: {
    anime: Array,
    twoMax: Boolean
  },
})
export default class AnimeCoverGrid extends Vue {
  anime!: IAnime[];
  twoMax!: boolean;
}
</script>

<style scoped>
.coverContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, 210px); /* the width is define here */
  grid-template-rows: auto auto; /* 2 rows */
  overflow: hidden; /* hide the overflow */
  margin: 0 auto;
  justify-content: center;
}

.twoMax {
  grid-auto-rows: 0px; /* next rows equal to 0 */
}
</style>
