<template>
  <div id="nav">
    <div style="float: left;"><router-link to="/"><img src="/logo.svg" height="100" /></router-link></div>
    <!-- <div style="float: right">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <Search />
    <div style="clear: both"></div>
  </div>
  <router-view/>
  <Advert />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Advert from '@/components/Advert.vue';
import Search from '@/components/Search.vue';

@Options({
  components: {
    Search,
    Advert,
  },
})
export default class App extends Vue {}
</script>

<style>
body {
  background-color: black;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  color: white;
}
span {
  color: white;
}

#nav {
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

a {
  color: white;
  text-decoration: none;
}
</style>
