<template>
  <div style="float: right;">
      <button data-toggle="modal" data-target="searchModal" v-on:click="toggle"><i class="material-icons">search</i></button>
  </div>
  <div style="clear: both"></div>
  <div id="searchModal" class="modal" :class="{active: isOpen}" v-on:keyup.esc="close" ref="modal">
    <div class="content">
      <input class="search-input" type="text" placeholder="Search" v-model="searchTerm" ref="search" />
      <div class="results">
        <ul>
          <li v-for="anime in results" :key="anime.slug" tabindex="0">
            <router-link :to="'/anime/' + anime.slug" v-on:click="close">
              <div class="search-result">
                  <img height="120" width="85" :src="anime.cover" />
                  <div class="search-result-info">
                    <span style="font-weight: 500">{{ anime.title }}</span>
                    <!-- <div v-html="highlight(anime.description)"></div> -->
                    <WordHighlighter highlightClass="highlight" splitBySpace="true" :query="searchTerm">{{ anime.description }}</WordHighlighter>
                    <!-- <div>{{ anime.description }}</div> -->
                    <!-- <div>
                      <span v-for="segment in anime.highlights" :key="segment.value">
                        <span :class="{highlight: segment.type == 'hit'}">{{ segment.value}}</span>
                      </span>
                    </div> -->
                  </div>
              </div>
            </router-link>
          </li>
        </ul>
        <!-- <h2>Technologies</h2>
        <ul>
          <li tabindex="0">AngularJS</li>
          <li tabindex="0">ReactJS</li>
          <li tabindex="0">Browserify</li>
          <li tabindex="0">Swift</li>
          <li tabindex="0">Webpack</li>
        </ul> -->
      </div><!-- end .results -->
    </div>
    <button data-dismiss="modal" v-on:click="close"><i class="material-icons">close</i><span>ESC</span></button>
  </div>
</template>

<script lang="ts">
import { ISearchResult } from '@/models/search_result';
import { APIConnector } from '@/utils/APIConnector';
import { Options, Vue } from 'vue-class-component';
import WordHighlighter from "vue-word-highlighter";

@Options({
  components: {
    WordHighlighter,
  }
})
export default class Search extends Vue {
  isOpen = false;
  searchTerm = "";
  declare $refs: Vue["$refs"] & {
    modal: HTMLInputElement,
    search: HTMLInputElement
  }
  results: ISearchResult[] = [];

  async created(): Promise<void> {
    this.$watch(
      () => this.searchTerm,
      async (term: string) => {
        if(term.length > 0) {
          await this.search(term);
        }
      });
  }

  async search(term: string): Promise<void> {
    console.log(term);
    this.results = await APIConnector.post(`/search`, { term });
    // this.anime = result.anime;
    // this.episodes = result.episodes;
  }

  // bindEvents() {
    
  //   document.body.addEventListener('keyup', (event) => {
  //     //If keyboard shortcut is ctrl + s
  //     if(event.ctrlKey && event.which === 83) {
  //       this.toggle();
  //     }
  //   });

  public toggle(): void {
    (this.isOpen) ? this.close() : this.open();
  }
  
  close(): void {
    this.isOpen = false;
    this.$refs.search.blur();
    // this.btn.focus();
    this.$refs.modal.addEventListener('transitionend', () => {
      setTimeout(() => {
        // this.search.value = "";
        document.documentElement.style.overflow = 'scroll';
        // eslint-disable-next-line
        (document.body as any).scroll = "yes";
      }, 100);
    });
  }
  
  open(): void {
    this.isOpen = true;
    this.$refs.modal.addEventListener('transitionend', () => {
      setTimeout(() => {
        this.$refs.search.focus();
        document.documentElement.style.overflow = 'hidden';
        // eslint-disable-next-line
        (document.body as any).scroll = "no";
      }, 100);
    });
  }

  highlight(text: string): string {
    if(text === undefined || text === null) return "";
    if(this.searchTerm === undefined || text === null) return "";
    return text.replace(new RegExp(this.searchTerm, "gi"), match => {
        return '<span class="highlight">' + match + '</span>';
    });
  }
}
</script>

<style>
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,700');
/* button i, [data-toggle='modal'] {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
button {
  position: relative;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}
[data-toggle='modal'] {
  background: #db579b;
  height: 3rem;
  width: 3rem;
  border-radius: 5px;
  margin-top: 10px;
  transition: all 0.3s ease;
}
[data-toggle='modal'] i {
  font-size: 2rem;
}
[data-toggle='modal']:focus {
  background: #db579b;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transform: translateY(1rem) translateZ(0);
  transition: all 0.3s ease;
  text-align: left;
}
.modal.active {
  visibility: visible;
  transform: translateY(0) translateZ(0);
  opacity: 1;
  z-index: 999;
  visibility: visible;
}
.modal.active .content {
  opacity: 1;
  visibility: visible;
}
.modal [data-dismiss='modal'] {
  position: absolute;
  height: 2rem;
  width: 2rem;
  top: 1rem;
  right: 1rem;
}
.modal [data-dismiss='modal']:focus i {
  color: #db579b;
}
.modal [data-dismiss='modal'] i {
  font-size: 2rem;
}
.modal [data-dismiss='modal'] span {
  display: block;
  color: rgba(255, 255, 255, 0.2);
  font-size: 0.5rem;
  transform: translateY(1.1rem);
}
.modal .content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 80%;
  margin: 0 auto;
  padding: 1rem 0;
  overflow: scroll;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0.3s;
}
.modal .content.show-tag-results > div.results {
  visibility: hidden;
  transform: translateX(-100%);
}
.modal .content.show-tag-results > div.tag-results {
  visibility: visible;
  transform: translateX(0);
}
.modal .content > div {
  position: absolute;
  width: 100%;
  top: 8rem;
  left: 0;
  transition: all 0.3s cubic-bezier(1, 0.1, 0, 0.9);
}
.modal .content > div.results {
  visibility: visible;
  transform: translateX(0);
}
.modal .content > div.tag-results {
  visibility: hidden;
  transform: translateX(100%);
}
.modal h2 {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  font-size: 0.75rem;
  margin-top: 0;
}
.modal input {
  display: block;
  width: 100%;
  background: none;
  border: 1px solid #db579b;
  border-width: 0 0 1px 0;
  color: white;
  padding: 1.5rem 0;
  font-size: 2rem;
  font-weight: 100;
  font-family: 'Roboto';
}
.modal input:focus {
  outline: none;
}
.modal ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.modal ul li {
  position: relative;
  color: #949494;
  font-size: 1.15rem;
  padding: 0.75rem 0.5rem 0.75rem 1.75rem;
  cursor: pointer;
}
/* .modal ul li:before {
  content: '';
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 0.5rem;
  margin-top: -4px;
  display: block;
  height: 8px;
  width: 8px;
  border: 2px solid white;
  border-radius: 100%;
} */
.modal ul li:hover, .modal ul li:focus {
  background: rgba(255, 255, 255, 0.1);
  outline: none;
}
.modal ul + h2 {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 2rem;
  margin-top: 1rem;
}
.modal .back {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
}
.modal .back i {
  font-size: 1.5rem;
}
.modal ::placeholder {
  color: rgba(255, 255, 255, 0.3);
  font-weight: 100;
  font-family: 'Roboto';
}
.material-icons {
  color: white;
}

.search-result {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.search-result-info {
  padding-left: 20px;
  color: white;
  font-weight: 100;
  max-height: 120px;
}

.search-result-info > span {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* max-height: 50px; */
  -webkit-line-clamp: 3;
}
.highlight {
  color: #db579b;
  background-color: initial;
}
</style>
