import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import VueGtag from "vue-gtag-next";
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App);
const head = createHead();

app.use(store);
app.use(router);
app.use(head);

app.use(VueGtag, {
    property: {
      id: "G-GTFL1CJJ3C"
    }
});
  
app.mount('#app');