<template>
  <iframe data-aa='1895996' src='//ad.a-ads.com/1895996?size=728x90' style='width:728px; height:90px; border:0px; padding:0; overflow:hidden; background-color: transparent;' :key="$route.fullPath">></iframe>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class Advert extends Vue {
}
</script>
