import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnimeCover = _resolveComponent("AnimeCover")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["coverContainer", { twoMax: _ctx.twoMax }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.anime, (ani) => {
      return (_openBlock(), _createBlock(_component_AnimeCover, {
        anime: ani,
        key: ani.id
      }, null, 8, ["anime"]))
    }), 128))
  ], 2))
}