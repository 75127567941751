<template>
  <div class="animeCover">
    <router-link :to="{ name: 'Anime', params: { slug: anime.slug } }">
        <img :src="anime.cover" />
        <span>{{ anime.title }}</span>
    </router-link>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { IAnime } from "@/models/anime";

@Options({
  props: {
    anime: Object
  }
})
export default class AnimeCover extends Vue {
  anime!: IAnime
}
</script>

<style scoped>
.animeCover {
    display: inline-flex;
    margin: 5px;
    width: 200px;
    height: 345px;
}
a {
    text-decoration: none;
    color: black;
    text-align: left;
    overflow: hidden;
}
img {
    width: 200px;
    height: 275px;
}
</style>
