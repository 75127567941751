<template>
  <Head>
    <title>Animoos - Best Rated</title>
    <meta name="description" :content="`Watch all the latest Anime for free on Animoos`"/>
  </Head>
  <div class="home">
    <h1>Best Rated Anime</h1>
      <AnimeCoverGrid :anime="anime" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Head } from '@vueuse/head'
import AnimeCoverGrid from '@/components/AnimeCoverGrid.vue';
import { IAnime } from '@/models/anime';
import { APIConnector } from '@/utils/APIConnector';

// TODO: This is nearly identical in code to Home, consider merging to components
@Options({
  components: {
    AnimeCoverGrid,
    Head
  },
})
export default class BestRated extends Vue {
  season = "";
  anime: IAnime[] = [];

  async created(): Promise<void> {
    this.anime = await APIConnector.get("/best-rated");
  }
}
</script>
