<template>
  <Head>
    <title>Animoos</title>
    <meta name="description" :content="`Watch all the latest Anime for free on Animoos`"/>
  </Head>
  <div class="home">
    <router-link :to="{ name: 'Seasonal' }">
      <h3>Seasonal <span class="chevron"></span></h3>
    </router-link>
    <AnimeCoverGrid :anime="seasonal" twoMax="true" />
    <router-link :to="{ name: 'Latest' }">
      <h3>Latest Releases <span class="chevron"></span></h3>
    </router-link>
    <AnimeCoverGrid :anime="latest" twoMax="true" />
    <router-link :to="{ name: 'Popular' }">
      <h3>Most Popular <span class="chevron"></span></h3>
    </router-link>
    <AnimeCoverGrid :anime="mostPopular" twoMax="true" />
    <router-link :to="{ name: 'BestRated' }">
      <h3>Best Rated <span class="chevron"></span></h3>
    </router-link>
    <AnimeCoverGrid :anime="bestRated" twoMax="true" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Head } from '@vueuse/head'
import AnimeCoverGrid from '@/components/AnimeCoverGrid.vue';

import { IAnime } from '@/models/anime';
import { APIConnector } from '@/utils/APIConnector';

@Options({
  components: {
    AnimeCoverGrid,
    Head,
  },
})
export default class Home extends Vue {
  public mostPopular: IAnime[] = [];
  public bestRated: IAnime[] = [];
  public latest: IEpisode[] = [];
  public seasonal: IAnime[] = [];

  async created(): Promise<void> {
    const data: IStats = await APIConnector.get("/stats");
    this.seasonal = data.seasonal;
    this.mostPopular = data.mostPopular;
    this.bestRated = data.bestRated;
    const latest = data.latestEpisodes;

    latest.map((ep: IEpisode) => {
      ep.title = ep.anime_title;
      ep.slug = ep.anime_slug;
      ep.id = ep.anime_id;
      ep.cover = `https://cdn.animoos.org/covers/${ep.anime_slug}.jpg`;
    })
    this.latest = latest;
  }
}

interface IStats {
  seasonal: IAnime[];
  mostPopular: IAnime[];
  bestRated: IAnime[];
  latestEpisodes: IEpisode[];
}

interface IEpisode {
  anime_title: string;
  anime_slug: string;
  anime_id: string;

  title: string;
  slug: string;
  id: string;
  cover: string;
}
</script>
<style scroped>
.chevron::before {
	border-style: solid;
	border-width: 0.25em 0.25em 0 0;
	content: '';
	display: inline-block;
	height: 0.45em;
	position: relative;
	top: -0.1em;
	vertical-align: middle;
	width: 0.45em;
	left: 0;
	transform: rotate(45deg);
}
</style>