import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-0c08e494"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "animeCover" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: { name: 'Anime', params: { slug: _ctx.anime.slug } }
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: _ctx.anime.cover
        }, null, 8, _hoisted_2),
        _createElementVNode("span", null, _toDisplayString(_ctx.anime.title), 1)
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}