import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { trackRouter } from "vue-gtag-next";

import Home from '../views/Home.vue';
import Popular from '../views/Popular.vue';
import Seasonal from '../views/Seasonal.vue';
import BestRated from '../views/BestRated.vue';
import Latest from '../views/Latest.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/seasonal',
    name: 'Seasonal',
    component: Seasonal
  },
  {
    path: '/popular',
    name: 'Popular',
    component: Popular
  },
  {
    path: '/best-rated',
    name: 'BestRated',
    component: BestRated
  },
  {
    path: '/latest',
    name: 'Latest',
    component: Latest
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/anime/:slug',
    name: 'Anime',
    component: () => import(/* webpackChunkName: "anime" */ '../views/Anime.vue'),
    props: true
  },
  {
    path: '/anime/:slug/episode/:number',
    name: 'Episode',
    component: () => import(/* webpackChunkName: "episode" */ '../views/Episode.vue'),
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

trackRouter(router);

export default router
