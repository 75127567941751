<template>
  <Head>
    <title>Animoos - Latest</title>
    <meta name="description" :content="`Watch all the latest Anime for free on Animoos`"/>
  </Head>
  <div class="home">
    <h1>Latest Releases</h1>
      <AnimeCoverGrid :anime="anime" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Head } from '@vueuse/head'
import AnimeCoverGrid from '@/components/AnimeCoverGrid.vue';
import { IAnime } from '@/models/anime';
import { APIConnector } from '@/utils/APIConnector';

// TODO: This is nearly identical in code to Home, consider merging to components
@Options({
  components: {
    AnimeCoverGrid,
    Head
  },
})
export default class Latest extends Vue {
  anime: IAnime[] = [];

  async created(): Promise<void> {
    const latest = await APIConnector.get("/latest");
    latest.map((ep: EpisodeMess) => {
      ep.title = ep.anime_title;
      ep.slug = ep.anime_slug;
      ep.id = ep.anime_id;
      ep.cover = `https://cdn.animoos.org/covers/${ep.anime_slug}.jpg`;
    })
    this.anime = latest;
  }
}

interface EpisodeMess {
  title: string;
  anime_title: string;
  slug: string;
  anime_slug: string;
  id: string;
  anime_id: string;
  cover: string;
}
</script>
