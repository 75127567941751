export class APIConnector {
    protected static baseUrl = "https://api.animoos.org";

    // eslint-disable-next-line
    public static async get(url: string): Promise<any> {
        return (await fetch(this.baseUrl + url)).json();
    }

    // POST data to API
    // eslint-disable-next-line
    public static async post(url: string, body: any): Promise<any> {
        const response = await fetch(this.baseUrl + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return response.json();
    }
}